import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
export const Route = createFileRoute('/faq')({
  component: FAQComponent,
})

function FAQComponent() {
return (
    <h3>Frequently Asked Questions</h3>
)
}
