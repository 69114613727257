import React, { useState, useEffect } from "react";
import Quote from "../assets/Quotes.png";
import bg1 from "../assets/background/bg1.png";
import bg2 from "../assets/background/bg2.png";

const testimonialsData = [
  {
    id: 1,
    name: "HM Tamborine",
    feedback:
      "We have been using the Smart Water Management Solution from Federate One for more than a year. And, are very satisfied with the operation and alerts provided by the system. It monitors the overhead tanks for our 3 apartment blocks and our Sump.",
  },
  {
    id: 2,
    name: "Pizza 4p's",
    feedback:
      "Federate One's solution has made our water management efficient and reliable. We no longer face water shortages thanks to timely alerts and accurate monitoring.",
  },
  {
    id: 3,
    name: "Shriram Sameeksha",
    feedback:
      "The system's performance is excellent. Real-time updates and easy control make it a must-have solution for modern apartments.",
  },
  {
    id: 4,
    name: "India's Private Limited",
    feedback:
      "We are very happy with the water management system. It has saved us time, effort, and water wastage significantly.",
  },
];

export const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleManualChange = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative py-6 bg- w-full max-w-[1450px] mx-auto mb-10">
    
      <img
        src={bg1}
        alt="Background Left"
        className="absolute w-[500px] h-auto object-cover bottom-0 left-0"
      />
      <img
        src={bg2}
        alt="Background Right"
        className="absolute w-[500px] h-auto object-cover top-0 right-0"
      />

      <h4 className="text-[40px] md:text-[60px] font-semibold text-[#3560A1] text-center">
        Client Testimonials
      </h4>

    
      <div className="relative mx-auto mt-12 w-[90%] md:w-[700px] h-[300px] overflow-hidden rounded-lg shadow-md">
        <div className="absolute inset-0 bg-gradient-to-b from-[#1E9CAD] to-[#1A8493] opacity-10"></div>

        {testimonialsData.map((testimonial, index) => (
          <div
            key={testimonial.id}
            className={`absolute inset-0 flex flex-col items-center justify-center transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
          >
           
            <div
              className="w-[60px] h-[60px] bg-cover mb-4"
              style={{ backgroundImage: `url(${Quote})` }}
            ></div>

          
            <div className="px-6 text-center">
              <p className="text-lg md:text-xl font-medium text-gray-700">
                {testimonial.feedback}
              </p>
              <p className="mt-4 text-lg font-semibold text-[#3560A1]">
                - {testimonial.name}
              </p>
            </div>
          </div>
        ))}
      </div>

     
      <div className="flex justify-center space-x-2 mt-6">
        {testimonialsData.map((_, i) => (
          <button
            key={i}
            className={`w-3 h-3 rounded-full ${
              i === currentIndex ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => handleManualChange(i)}
          />
        ))}
      </div>
    </div>
  );
};
