import React from 'react';
import image1 from '../assets/discover/LiveUpdates.png';
import image2 from '../assets/discover/MobileControl.png';
import image3 from '../assets/discover/SeamlessI.png';
import image4 from '../assets/discover/BudgetFriendly.png';
import image5 from '../assets/discover/SmartAnalytics.png';
import image6 from '../assets/discover/IntrusiveSensor.png';

export const Discover = () => {
  const Data = [
    { heading: "Live Updates", imageSrc: image1 },
    { heading: "Mobile Control", imageSrc: image2 },
    { heading: "Seamless Interaction", imageSrc: image3 },
    { heading: "Budget Friendly", imageSrc: image4 },
    { heading: "Smart Analytics", imageSrc: image5 },
    { heading: "Non-Intrusive Sensor", imageSrc: image6 },
  ];

  return (
    <div className="flex justify-center w-full py-6">
      <div className="relative w-full max-w-[1000px] h-auto bg-[#f3f1ed] mx-auto px-4 rounded-lg">
        
        <div className="flex flex-wrap w-full">
          <div className="w-full md:w-2/3 mb-4 md:mb-0">
            <div className=" text-center relative font-bold text-5xl ml-10 mt-10">
              <h3>Discover Us
              </h3>
            </div>
            <div className="font-bold text-[26px] ml-10 mt-6 text-[#3560A1] text-center">
              <h4>Smart Water Management Solutions</h4>
            </div>
            <div className="font-regular text-[20px] ml-10 mt-6 text-justify">
              <p>
                Introducing the HydroSeries by FEDERATE ONE – an IoT-powered water management system that 
                adapts to various needs, automates usage, conserves resources, and ensures device efficiency. 
                Bid farewell to wasteful water usage and manual supervision with smarter, sustainable water management
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/3 grid grid-cols-2 gap-6 p-2 place-items-center">
            {Data.map((frame, index) => (
              <div
                key={index}
                className="flex flex-col items-center bg-[#E6F4FA] rounded-lg p-2 shadow-md transition-all duration-300 ease-in-out hover:shadow-lg hover:bg-[#D4EBF7] border-2  border-[#3560a1] hover:border-4 h-[120px] w-[120px]"
              >  
              
                <h5 className="text-[12px] text-[#000000] font-normal mb-2  text-center break-words">{frame.heading}</h5>
                <img
                  src={frame.imageSrc}
                  alt={`Frame ${index + 1}`}
                  className="w-[70px] h-[60px] object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
