export const Differential = () => {
  const frameData = [
    {
      heading: "WiFi or SIM Connectivity",
      imageSrc: "https://via.placeholder.com/300?text=Image+1",
      description: "The device is compatible with GSM (IoT SIM) and Wi-Fi And ensures continuous monitoring by connecting to the cloud.",
    },
    {
      heading: "Advanced Sensing Technology",
      imageSrc: "https://via.placeholder.com/300?text=Image+2",
      description: "Provides accurate readings of water in real time with the help of advanced waterproof sensors with minimal intervention.",
    },
    {
      heading: "Comprehensive Reporting Options",
      imageSrc: "https://via.placeholder.com/300?text=Image+3",
      description: "Curates data on consumption, trends, and filled  for any given day, week, or month that users can examine. These reports are generated with extensive data collected.",
    },
    {
      heading: "Online User Manual",
      imageSrc: "https://via.placeholder.com/300?text=Image+4",
      description: "A manual  that provides easy-to-follow instructions to operate and troubleshooting the device helps users quickly adapt.",
    },
    {
      heading: "Subscription & Support",
      imageSrc: "https://via.placeholder.com/300?text=Image+5",
      description: "Our usual solutions include support, free software and hardware updates, and device replacement in the event of a fault.",
    },
    {
      heading: "User-Friendly App",
      imageSrc: "https://via.placeholder.com/300?text=Image+6",
      description: "The app's user-friendly design makes it simple for users to modify the tank’s limit to suit their needs.",
    },
    {
      heading: "Flexible Tank Adaptability",
      imageSrc: "https://via.placeholder.com/300?text=Image+7",
      description: "Suitable for tanks with various dimensions and forms. allows for a variety of sump and overhead tank configurations.",
    },
    {
      heading: "Proactive Remote Monitoring",
      imageSrc: "https://via.placeholder.com/300?text=Image+8",
      description: "Track water levels and device status directly from the office ensuring prompt alerts for any issues-no need to rely on customer notifications",
    },
  ];

  return (
    <div className="relative w-full max-w-[1000px] bg-white flex flex-col items-center justify-center px-4 py-10 mt-10 mx-auto">
      <div className="relative w-full max-w-[1000px] h-auto bg-[#edf3ec] mx-auto px-4 py-10 rounded-lg">

      <div className="relative text-center font-bold text-4xl text-center mb-6">
        <h3>
          What Makes Federate One Different?
        </h3>
      </div>

      <div className="font-bold text-2xl text-[#3560A1] text-center mb-8">
        <h4>Small Actions, Big Impact</h4>
      </div>

      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full max-w-[1400px] mx-auto justify-items-center">
        {frameData.map((frame, index) => (
          <div key={index} className="group perspective w-[220px] h-[200px]">
            <div className="relative w-full h-full transform-style preserve-3d duration-500 group-hover:rotate-y-180">
                    
          <div className="absolute w-full h-full bg-gray-100 hover:bg-[#D4EBF7] border-2  border-[#20a367]  flex flex-col justify-center items-center text-[#222222] backface-hidden p-4">
          <h5 className="font-bold text-[12px] mb-4 text-center break-words">{frame.heading}</h5>
          <img
            src={frame.imageSrc}
            alt={`Frame ${index + 1} Image`}
            className="w-[120px] h-[120px] object-cover mb-4"
          />
        </div>
              <div className="absolute w-full h-full bg-[#CBE6EF] text-[#3560a1] flex justify-center items-center text-center px-4 backface-hidden rotate-y-180 text-[#222222] border-2  border-[#3560a1] text-[14px]">
                <p>{frame.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};
