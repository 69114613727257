import * as React from "react";
import { createFileRoute } from "@tanstack/react-router";
import { FaCog, FaBell, FaLeaf } from 'react-icons/fa'; 
import { MdAccessTime } from 'react-icons/md'; 
import { FaBuilding } from 'react-icons/fa';
import fed from "../components/assets/fed.svg";
import about from '../components/assets/about.jpg';
import fengbo from '../components/assets/fengbo_logo.png'
import  { AiOutlineBulb } from 'react-icons/ai';
import hmt from '../components/assets/about/hmt_logo.png'
import pizza from '../components/assets/about/pizza_logo.png'
import ss from '../components/assets/about/ss_logo.png'
import waters from '../components/assets/about/waters_logo.png'

export const Route = createFileRoute("/about")({
  component: aboutComponent,
});

function aboutComponent() {
  return (
    <div>
      <div className="flex max-w-[1200px] gap-2 mx-auto">
        <div className="bg-[#edf3ed] w-[600px] h-[450px] mx-auto rounded-lg mb-6">
          <h3 className="text-[#222222] ml-8 mt-8">
            <span className="font-extrabold mr-2 text-[36px]">About</span>
            <span className="font-bold text-[30px]">us</span>
          </h3>
          <p className="text-[20px] font-regular m-4">
            At Federate One, we are dedicated to simplifying water management through innovative IoT solutions.
            Our mission is to help communities and organizations conserve water, reduce wastage, 
            and optimize resource usage. By addressing critical challenges in water management, we aim to ensure sustainability 
            and smarter water usage for the future. We work closely with our clients to deliver customized solutions that meet their specific needs, 
            combining expertise and technology to create meaningful impact.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex max-w-[1200px] gap-2 mx-auto">
            <div className="flex w-full max-w-[600px] h-[130px] rounded-lg bg-[#EDEFF3] items-center text-center">
              <img
                src={fed}
                alt="Federate One"
                className="w-[150px] h-auto mr-4 w-[25%]"
              />
              <h3 className="text-[#222222] flex flex-wrap justify-center w-[65%]">
              <span className="mr-2 text-[30px] font-extrabold">Empowering</span>
              <span className="mr-2 text-[26px]">the</span>
              <span className="mr-2 text-[28px] font-extrabold">Future</span>
              <span className="mr-2 text-[26px]">with</span>
              <span className="mr-2 text-[26px] font-extrabold">IoT</span>
            </h3>
            </div>
          </div>
          
          <div className="flex flex-col w-[600px] h-[300px] bg-[#EEE8E8] pt-4">
            <h3 className="text-[#222222]">
              <span className="text-[28px] font-bold ml-8 mr-2">Working</span>
              <span className="text-[28px] font-bold mr-2">With the</span>
              <span className="text-[34px] font-extrabold">Best</span>
            </h3>
            <h3 className="ml-8 pt-6 text-[20px] font-regular">Henan Fengbo Automation Co.,LTD</h3>
            <img
            src= {fengbo}
            className="justify-center w-[200px] h-[200px] mx-auto items-center"
            />
          </div>
        </div>
      </div>
      <div className="max-w-[1200px] mx-auto py-8">
        <h3>
          <span className="text-[38px] mr-2 font-extrabold">Exclusive</span>
          <span className="text-[38px] font-extrabold mr-2">Features</span>
          <span className="text-[28px] font-bold mr-2">We</span>
          <span className="text-[28px] font-bold mr-2">Bring</span>
        </h3>

        <div className="flex flex-wrap justify-between gap-2 py-6 text-[#222222]">
          <div className="flex flex-wrap gap-4 w-[60%]">
            <div className="relative flex w-[250px] h-[120px] bg-[#f3f1ed] rounded-lg p-6">
              <h3 className="text-[#222222]">
                <span className="text-[22px] mr-2 font-extrabold">Smart</span>
                <span className="text-[22px] font-extrabold mr-2">IoT</span>
                <span className="text-[18px] font-bold">Power</span>
              </h3>
              <AiOutlineBulb  className="absolute bottom-4 right-4 text-[#222222]" size={36}/>
            </div>

            <div className="relative flex w-[210px] h-[120px] bg-[#f3f1ed] rounded-lg p-6">
              <h3 className="text-[#222222]">
                <span className="text-[22px] mr-2 font-extrabold">Deep</span>
                <span className="text-[18px] font-bold">Insights</span>
              </h3>
              <FaCog className="absolute bottom-4 right-4 text-[#222222]" size={32} />
            </div>

            <div className=" relative flex w-[215px] h-[120px] bg-[#f3f1ed] rounded-lg p-6">
              <h3 className="text-[#222222]">
                <span className="text-[22px] mr-2 font-extrabold">Save</span>
                <span className="text-[22px] font-extrabold mr-2">&</span>
                <span className="text-[18px] font-bold">Conserve</span>
              </h3>
              <FaLeaf className ="absolute bottom-4 right-4 text-[#222222]" size={30}/>
            </div>

            <div className="relative flex w-[220px] h-[120px] bg-[#f3f1ed] rounded-lg p-6">
              <h3 className="text-[#222222]">
                <span className="text-[22px] mr-2 font-extrabold">Flexible</span>
                <span className="text-[18px] font-bold">Setup</span>
              </h3>
              <FaBuilding className="absolute bottom-4 right-4 text-[#222222]" size={32}/>
            </div>

            <div className="relative flex w-[225px] h-[120px] bg-[#f3f1ed] rounded-lg p-6">
              <h3 className="text-[#222222]">
                <span className="text-[22px] mr-2 font-extrabold">Instant</span>
                <span className="text-[28px] font-bold">Alerts</span>
              </h3>
              <FaBell className="absolute bottom-4 right-4 text-[#222222]" size={32} />
            </div>

            <div className="relative flex w-[230px] h-[120px] bg-[#f3f1ed] rounded-lg p-6">
              <h3 className="text-[#222222]">
                <span className="text-[22px] mr-2 font-extrabold">Always</span>
                <span className="text-[18px] font-bold">Active</span>
              </h3>
              <MdAccessTime className="absolute bottom-4 right-4 text-[#222222]" size={32} />
            </div>
            </div>

          <div className="w-[35%] h-[300px]">
            <img
              src={about}
              className="w-full h-[auto] max-h-[300px] rounded-lg"
              alt="About image"
            />
          </div>
        </div>
      </div>

      <div className="py-6">
        <h3 className="text-[#222222] max-w-[1200px] mx-auto">
          <span className="text-[38px] mr-2 font-extrabold">Secured </span>
          <span className="text-[30px] mr-2 font-bold">&</span>
          <span className="text-[38px] mr-2 font-extrabold">Satisfied </span>
          <span className="text-[38px] mr-2 font-extrabold">Clients</span>
        </h3>
    

      <div className="flex bg-[#edf3ec] max-w-[700px] h-auto py-4 w-[1200px] mx-auto gap-8 text-center justify-center items-center mt-6 mb-10 rounded-lg">
        <img
        src= {hmt}
        className="h-[25px] h-[25px]"
        />
         <img
        src= {pizza}
        className="h-[80px] h-[80px]"
        />
         <img
        src= {ss}
        className="h-[70px] h-[70px]"
        />
         <img
        src= {waters}
        className="h-[100px] h-[100px]"
        />
       

      </div>

      </div>
    </div>
  );
}
