/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SolutionsImport } from './routes/solutions'
import { Route as ProductsImport } from './routes/products'
import { Route as Product4Import } from './routes/product4'
import { Route as Product3Import } from './routes/product3'
import { Route as Product2Import } from './routes/product2'
import { Route as FaqImport } from './routes/faq'
import { Route as ContactImport } from './routes/contact'
import { Route as AboutImport } from './routes/about'
import { Route as IndexImport } from './routes/index'

// Create/Update Routes

const SolutionsRoute = SolutionsImport.update({
  id: '/solutions',
  path: '/solutions',
  getParentRoute: () => rootRoute,
} as any)

const ProductsRoute = ProductsImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => rootRoute,
} as any)

const Product4Route = Product4Import.update({
  id: '/product4',
  path: '/product4',
  getParentRoute: () => rootRoute,
} as any)

const Product3Route = Product3Import.update({
  id: '/product3',
  path: '/product3',
  getParentRoute: () => rootRoute,
} as any)

const Product2Route = Product2Import.update({
  id: '/product2',
  path: '/product2',
  getParentRoute: () => rootRoute,
} as any)

const FaqRoute = FaqImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => rootRoute,
} as any)

const ContactRoute = ContactImport.update({
  id: '/contact',
  path: '/contact',
  getParentRoute: () => rootRoute,
} as any)

const AboutRoute = AboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutImport
      parentRoute: typeof rootRoute
    }
    '/contact': {
      id: '/contact'
      path: '/contact'
      fullPath: '/contact'
      preLoaderRoute: typeof ContactImport
      parentRoute: typeof rootRoute
    }
    '/faq': {
      id: '/faq'
      path: '/faq'
      fullPath: '/faq'
      preLoaderRoute: typeof FaqImport
      parentRoute: typeof rootRoute
    }
    '/product2': {
      id: '/product2'
      path: '/product2'
      fullPath: '/product2'
      preLoaderRoute: typeof Product2Import
      parentRoute: typeof rootRoute
    }
    '/product3': {
      id: '/product3'
      path: '/product3'
      fullPath: '/product3'
      preLoaderRoute: typeof Product3Import
      parentRoute: typeof rootRoute
    }
    '/product4': {
      id: '/product4'
      path: '/product4'
      fullPath: '/product4'
      preLoaderRoute: typeof Product4Import
      parentRoute: typeof rootRoute
    }
    '/products': {
      id: '/products'
      path: '/products'
      fullPath: '/products'
      preLoaderRoute: typeof ProductsImport
      parentRoute: typeof rootRoute
    }
    '/solutions': {
      id: '/solutions'
      path: '/solutions'
      fullPath: '/solutions'
      preLoaderRoute: typeof SolutionsImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/contact': typeof ContactRoute
  '/faq': typeof FaqRoute
  '/product2': typeof Product2Route
  '/product3': typeof Product3Route
  '/product4': typeof Product4Route
  '/products': typeof ProductsRoute
  '/solutions': typeof SolutionsRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/contact': typeof ContactRoute
  '/faq': typeof FaqRoute
  '/product2': typeof Product2Route
  '/product3': typeof Product3Route
  '/product4': typeof Product4Route
  '/products': typeof ProductsRoute
  '/solutions': typeof SolutionsRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/contact': typeof ContactRoute
  '/faq': typeof FaqRoute
  '/product2': typeof Product2Route
  '/product3': typeof Product3Route
  '/product4': typeof Product4Route
  '/products': typeof ProductsRoute
  '/solutions': typeof SolutionsRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/about'
    | '/contact'
    | '/faq'
    | '/product2'
    | '/product3'
    | '/product4'
    | '/products'
    | '/solutions'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/about'
    | '/contact'
    | '/faq'
    | '/product2'
    | '/product3'
    | '/product4'
    | '/products'
    | '/solutions'
  id:
    | '__root__'
    | '/'
    | '/about'
    | '/contact'
    | '/faq'
    | '/product2'
    | '/product3'
    | '/product4'
    | '/products'
    | '/solutions'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AboutRoute: typeof AboutRoute
  ContactRoute: typeof ContactRoute
  FaqRoute: typeof FaqRoute
  Product2Route: typeof Product2Route
  Product3Route: typeof Product3Route
  Product4Route: typeof Product4Route
  ProductsRoute: typeof ProductsRoute
  SolutionsRoute: typeof SolutionsRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AboutRoute: AboutRoute,
  ContactRoute: ContactRoute,
  FaqRoute: FaqRoute,
  Product2Route: Product2Route,
  Product3Route: Product3Route,
  Product4Route: Product4Route,
  ProductsRoute: ProductsRoute,
  SolutionsRoute: SolutionsRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/about",
        "/contact",
        "/faq",
        "/product2",
        "/product3",
        "/product4",
        "/products",
        "/solutions"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/about": {
      "filePath": "about.tsx"
    },
    "/contact": {
      "filePath": "contact.tsx"
    },
    "/faq": {
      "filePath": "faq.tsx"
    },
    "/product2": {
      "filePath": "product2.tsx"
    },
    "/product3": {
      "filePath": "product3.tsx"
    },
    "/product4": {
      "filePath": "product4.tsx"
    },
    "/products": {
      "filePath": "products.tsx"
    },
    "/solutions": {
      "filePath": "solutions.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
