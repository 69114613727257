// src/routes/__root.tsx
import { createRootRoute, Link, Outlet } from "@tanstack/react-router";
import { Headers } from '../components/Headers/headers'; 
import { Footer } from '../components/Footer/footer'; 

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  return (
    <>
      <Headers />
      <Outlet />
      <Footer/>
    </>
  );
}

