import React from 'react';
import ReactDOM from 'react-dom/client';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen'; 
import './index.css'


const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  })
  
  declare module '@tanstack/react-router' {
  interface Register {
  router: typeof router
   }
  }

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
   
    <RouterProvider router={router} />
  </React.StrictMode>
);
