import * as React from 'react'
import p1 from '../components/assets/products/Product1.png'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/product2')({
  component: Product2Component,
})

function Product2Component() {
  return (
    <div>
      <div className="flex  justify-center gap-4 items-start p-4 max-w-[1000px] mx-auto">
        <div className="w-64 h-[300px] flex items-center justify-center border-2 border-gray-300 bg-white rounded-lg shadow-lg">
          <img
            src={p1}
            alt="Product 1"
            className="max-h-full max-w-full object-contain"
          />
        </div>

        <div className="w-64 h-[300px] flex flex-col justify-center border rounded-lg shadow-lg bg-[#d9dfda] p-4">
          <p className="text-lg font-semibold text-center mb-4">
            HydroSmart IQ
          </p>
          <ul className="list-disc pl-5 text-gray-700">
            <li>Supports multiple tanks and pumps</li>
            <li>Automatic pump operations</li>
            <li>Remote operation and control</li>
            <li>Manual override option</li>
            <li>For residences, villas, apartments, and farms</li>
          </ul>
        </div>
      </div>
      <div className="bg-[#cbe6ef] w-[600px] h-[300px] mx-auto mt-10 mb-10 rounded-lg">
        <p className="text-[24px] font-bold ml-4 py-4">HydroSmart Features</p>
      </div>
    </div>
  )
}
