import logo from '../assets/logo.svg';
import Arrow from '../assets/URArrow.png';
import { CiLocationOn } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from '@tanstack/react-router';

export const Footer = () => {
  return (
    <div className="bottom-0 w-full max-w-[1450px] mx-auto bg-[#f3f1ed] rounded-lg">
    <div className="flex flex-row justify-between px-10 py-20">
    
      <div className="flex flex-col items-center w-1/4">
        <img src={logo} alt="logo" className="h-[180px] w-[200px]" />
      </div>
  
 
      <div className="flex flex-col items-start w-1/4">
        <h4 className="font-bold text-xl mb-4 text-black text-left">Address</h4>
        <ul className="space-y-2 text-black text-md text-left">
          <li className="flex items-start">
            
            <CiLocationOn className='h-6 w-6 mr-2'/>
            <div>
              <p>134, 135, 2nd Floor LRDE Layout,</p>
              <p>Karthik Nagar, Doddanekundi, Bengaluru,</p>
              <p>Karnataka 560037</p>
            </div>
          </li>
          <li className="flex items-start">
            <IoCallOutline className='h-6 w-6 mr-2'/>
            +91 7026455445
          </li>
          <li className="flex items-start">
            <MdOutlineEmail className="h-6 w-6 mr-2 " />
            sales@federate.one
          </li>
        </ul>
      </div>
  
      
      <div className="flex flex-col items-center w-1/4">
  <h4 className="font-bold text-xl mb-4 text-black">Solutions</h4>
  <ul className="text-black text-md">
    <li className="flex items-center mb-2">
      <img
        src={Arrow}
        alt="background"
        className="w-[25px] h-[25px] object-cover mr-4 transform hover:rotate-45 transition-transform duration-300"
      />
      Solution 1
    </li>
    <li className="flex items-center mb-2">
      <img
        src={Arrow}
        alt="background"
        className="w-[25px] h-[25px] object-cover mr-4 transform hover:rotate-45 transition-transform duration-300"
      />
      Solution 2
    </li>
    <li className="flex items-center mb-2">
      <img
        src={Arrow}
        alt="background"
        className="w-[25px] h-[25px] object-cover mr-4 transform hover:rotate-45 transition-transform duration-300"
      />
      Solution 3
    </li>
  </ul>
</div>


      <div className="flex flex-col items-center w-1/4">
        <h4 className="font-bold text-xl mb-4 text-black text-left">Company</h4>
        <ul className="text-black text-md text-left">
        <Link
        to = '/about'>
        <li className="flex items-center mb-2">
      <img
        src={Arrow}
        alt="background"
        className="w-[25px] h-[25px] object-cover mr-4 transform hover:rotate-45 transition-transform duration-300"
      />
      About us
    </li>
    </Link>
    <li className="flex items-center mb-2">
      <img
        src={Arrow}
        alt="background"
        className="w-[25px] h-[25px] object-cover mr-4 transform hover:rotate-45 transition-transform duration-300"
      />
      Contact Us
    </li>
    <li className="flex items-center mb-2">
      <img
        src={Arrow}
        alt="background"
        className="w-[25px] h-[25px] object-cover mr-4 transform hover:rotate-45 transition-transform duration-300"
      />
      Privacy Policy
    </li>
  </ul>
</div>
    </div>
  
    <hr className="border-t-1 border-black max-w-[1200px] mx-auto" />
  
    <div className="flex justify-center items-center py-6 px-10 w-full bg-[#f3f1ed]">
      <a href="/privacy-policy" className="text-black">
        © 2024 Federate Consulting Pvt. Ltd. All Rights Reserved
      </a>
    </div>
  </div>
  )}