import * as React from 'react';
import p1 from '../components/assets/products/Product1.png';
import p2 from '../components/assets/products/Product2.png';
import p3 from '../components/assets/products/Product3.png';
import p4 from '../components/assets/products/Product4.png';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/products')({
  component: ProductComponent,
});

function ProductComponent() {
  const frameData = [
    {
      heading: "HydroSmart",
      imageSrc: p1,
      description: "The device is compatible with GSM (IoT SIM) and Wi-Fi and ensures continuous monitoring by connecting to the cloud.",
    },
    {
      heading: "HydroSmart IQ",
      imageSrc: p2,
      description: "Provides accurate readings of water in real time with the help of advanced waterproof sensors with minimal intervention.",
    },
    {
      heading: "HydroSmart IQ Pro",
      imageSrc: p3,
      description: "Curates data on consumption, trends, and fills for any given day, week, or month that users can examine. These reports are generated with extensive data collected.",
    },
    {
      heading: "HydroSense",
      imageSrc: p4,
      description: "A manual that provides easy-to-follow instructions to operate and troubleshoot the device helps users quickly adapt.",
    },
  ];

  return (
    <div className="p-2">
      <h3 className="text-5xl text-[#000000] text-center">Our Products</h3>
      <div className="font-bold text-[26px] ml-10 mt-6 text-[#3560A1] text-center">
        <h4>Explore the smarter, more efficient ways to manage your water systems.</h4>
      </div>
      <div className='flex'>
        <div>
        
        </div>
        <div>

        </div>

      </div>
       

    </div>
  );
}
