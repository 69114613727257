import * as React from 'react'
import { Intro } from '../components/Introduction/introductionpage'
import { Discover } from '../components/Discover/discover'
import { Products } from '../components/Products/products'
import { Differential } from '../components/Differential/differential'
import { Testimonials } from '../components/Testimonials/testimonials'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/')({
  component: HomeComponent,
})

function HomeComponent() {
  return (
    <>
      <Intro />
      <Discover />
      <Products />
      <Differential />
      <Testimonials />
    </>
  )
}
