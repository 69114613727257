import p1 from '../assets/products/Product1.png';
import p2 from '../assets/products/Product2.png';
import p3 from '../assets/products/Product3.png';
import p4 from '../assets/products/Product4.png';
import { Link } from '@tanstack/react-router';


export const Products = () => {
  const frameData = [
    {
      heading: "HydroSmart",
      imageSrc: p1,
      description: "The device is compatible with GSM (IoT SIM) and Wi-Fi and ensures continuous monitoring by connecting to the cloud.",
    },
    {
      heading: "HydroSmart IQ",
      imageSrc: p2,
      description: "Provides accurate readings of water in real time with the help of advanced waterproof sensors with minimal intervention.",
    },
    {
      heading: "HydroSmart IQ Pro",
      imageSrc: p3,
      description: "Curates data on consumption, trends, and fills for any given day, week, or month that users can examine. These reports are generated with extensive data collected.",
    },
    {
      heading: "HydroSense",
      imageSrc: p4,
      description: "A manual that provides easy-to-follow instructions to operate and troubleshoot the device helps users quickly adapt.",
    },
  ];
    
return(
<div>

<div className="relative w-full max-w-[1000px] h-auto bg-[#FAFAFA] mx-auto px-4 py-2 rounded-lg mt-10">
<div className=" relative font-bold  text-5xl text-center ml-10 mt-6  ">
          <h3>Our Products
          <div className="relative">
          </div>

    </h3>
    </div>

        <div className="font-bold text-[26px] ml-10 mt-6 text-[#3560A1] text-center ">
        <h4>Explore the smarter, more efficient   to manage your water systems.</h4>
        </div>
        <div className="flex justify-center items-center mt-10 space-x-8 mb-10">
        {frameData.map((frame, index) => (
          <div
            key={index}
            className="relative group w-64 h-64 overflow-hidden rounded-lg shadow-lg cursor-pointer"
          >
          <Link
            to="/products"
          > 
            <img
              src={frame.imageSrc}
              alt={frame.heading}
              className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500 bg-[#ffffff]"
            />
          
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex justify-center items-center text-white p-4">
            <div className="text-center flex flex-col items-center">
            <h5 className="text-xl font-semibold mb-2 break-words">{frame.heading}</h5>
            <p className="break-words">{frame.description}</p>
            </div>
            </div>
            </Link>

          </div>
        ))}
      </div>
      </div>
</div>
)
}