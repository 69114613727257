import image from '../assets/background/background.png';
import Button  from '../Button/Button';
import { Link } from '@tanstack/react-router';


type Props = {};

export const Intro = ({}: Props) => {
  return (
    <div className="relative">
      
      <img src={image} alt="background" className="w-full max-w-[1200px] mx-auto h-[763px] object-cover rounded-lg" />
 
      <div
        className="absolute inset-0 max-w-[1200px] mx-auto rounded-lg"
        style={{
          background:
            'linear-gradient(259.8deg, rgba(17, 129, 144, 0.5) 14.66%, rgba(136, 136, 136, 0) 92.62%)',
          transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        }}
      ></div>

     
<div className="absolute top-0 w-full max-w-[1200px] mx-auto h-full flex text-white" style={{ left: '280px' }}>
  <div className="mt-10 gap-4 text-6xl font-bold block">
    <p>Explore</p>
    <p>Our Smart</p>
    <p>Solution</p>
  </div>
</div>

      <div className="absolute top-[20rem] left-[200px] text-white ml-20 mt-20 text-2xl font-bold block w-1/2">
      <p>
      "FEDERATE ONE’s HydroSeries offers smart water management for homes, villas, apartments, 
      farms, resorts, and industries, focusing on water conservation, efficiency, and sustainability.</p>
      </div>

      <div className="absolute top-[-5rem] left-[280px] top-[650px]">
          <Link
          to = '/solutions'>
      <Button label="Explore"/>
      </Link>
      </div>

    </div>
  );
};


