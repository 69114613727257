import * as React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import Button  from '../components/Button/Button';
import instagram from '../components/assets/contacts/instagram.svg';
import facebook from '../components/assets/contacts/facebook.svg';
import linkedin from '../components/assets/contacts/linkedin.svg';
import telephone from '../components/assets/telephone image.png'

export const Route = createFileRoute('/contact')({
  component: ContactComponent,
});

function ContactComponent() {
  return (
    <div className="relative">
     
          <div className='flex justify-center items-start'>
            <div className='flex flex-col'>
          <div className="w-[650px] h-[290px] bg-[#D9DFDA] rounded-[30px] mt-10 mb-10">
        <div className="w-[656px] h-[79px] font-syne font-bold text-[33px] leading-[43px] text-center text-black mt-8 mx-auto">
         < p>Contact us </p>
         <p>to explore smarter solutions!</p>
        </div>

        <div className="flex justify-between w-full mt-[4rem] ml-10">
          <div className="w-[331px] h-[120px] font-syne font-bold text-[14px] leading-[24px] text-black">
            Address: Federate Consulting Pvt. Ltd. #134-135, LRDE Layout, Karthik Nagar, Doddanekundi, Bengaluru 560037
          </div>
          <div className="flex flex-col space-y-4 w-[430px]">
            <div className="font-syne font-bold text-[14px] text-black">
              Email: sales@federate.one
            </div>
            <div className="font-montserrat font-bold text-[14px] leading-[24px] text-black rotate-[0.05deg]">
              Phone: +91 7026455445
            </div>
          </div> 
        </div>
      </div>


  <div className="flex items-start space-x-12">
  <div className="w-[220px] h-[100px] flex items-center justify-center bg-[#CBE6EF] rounded-[30px]">
    <div className="flex items-center justify-center space-x-4">
      <a href ='https://www.instagram.com/federateiot?igsh=MTBlOTJ5dnM0OWxtZQ=='
       target="_blank" 
        rel="noopener noreferrer">
      <img src={instagram} alt="instagram" className="h-14 w-14" />
      </a>
      <a href = 'https://www.linkedin.com/company/federate-iot/posts/?feedView=all'
       target="_blank" 
    rel="noopener noreferrer">
      <img src={linkedin} alt="linkedin" className="h-14 w-14" />
      </a>
      <a href="https://www.facebook.com/profile.php?id=61569351280053"
       target="_blank" 
       rel="noopener noreferrer">
        <img src={facebook} alt="facebook" className="h-14 w-14" />
      </a>
    </div>
  </div>

  <div className="w-[360px] h-[180px] flex items-center">
    <img src={telephone} alt="telephone" className="h-full" />
  </div>
</div>
</div>


<div className="relative w-[520px] h-[530px] bg-[#CBE6EF] rounded-[11px] mt-10 ml-10 mb-10">
  <div className="absolute w-full h-full p-6">
    <div className="font-syne font-bold text-[35px] leading-[58px] text-black mb-6">
      Get In Touch
    </div>

    <form className="space-y-12">
      <div className="flex flex-col">
        <input
          id="fullName"
          type="text"
          placeholder="Full Name"
          className="bg-transparent border-b-2 border-black  outline-none font-syne font-normal text-[14px] placeholder-black pb-4"
        />
      </div>

      <div className="flex flex-col">
        <input
          id="email"
          type="email"
          placeholder="Enter your email ID"
          className="bg-transparent border-b-2 border-black  outline-none font-syne font-normal text-[14px] placeholder-black pb-4"
        />
      </div>

      <div className="flex flex-col">
        <input
          id="phoneNumber"
          type="text"
          placeholder="Enter your phone number"
          className="bg-transparent border-b-2 border-black  outline-none font-syne font-normal text-[14px] placeholder-black pb-4"
        />
      </div>

      <div className="flex flex-col">
        <textarea
          id="message"
          placeholder="Enter your message"
          className="bg-transparent border-b-2 border-black  outline-none font-syne font-normal text-[14px] placeholder-black"
        ></textarea>
      </div>

      <Button label="Submit" />
    </form>
  </div>
</div>
</div></div>
  );
}
