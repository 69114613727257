type Props = {
    label: string;
    onClick?: () => void;
   
  };
  
  export default function Button({ label, onClick}: Props) {
    return (
      <button onClick = {onClick}
        className="absolute w-[178px] h-[60px] bg-black  rounded-[30px] rotate-[1.09deg] text-white hover:bg-[rgba(0,0,0,0.50)] transition duration-300 ease-in-out text-lg"
      >
        {label}
      </button>
    );
  }
  