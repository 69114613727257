import { useState } from 'react';
import logo from '../assets/logo.svg';
import { Link } from '@tanstack/react-router';
import { FaBars, FaTimes } from 'react-icons/fa';
import p5 from '../assets/header.png';

export const Headers = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex items-center justify-between px-4 sm:px-8 lg:px-16 bg-[#FFFFFF]">

      <img src={logo} alt="logo" className="h-[180px] w-[200px] ml-20"/>


      <div className="sm:hidden" onClick={toggleMenu}>
        {isOpen ? (
          <FaTimes className="text-2xl cursor-pointer" />
        ) : (
          <FaBars className="text-2xl cursor-pointer" />
        )}
      </div>
     
      <div className="hidden sm:flex gap-8 items-center ml-auto text-lg mr-20">
      <Link
          to="/"
          className="hover:text-red-500 cursor-pointer"
        >  Home</Link>
           
           
           <div className="relative group">
  <p className="cursor-pointer hover:text-red-500">Products</p>
  <div className="absolute left-0 top-full w-full h-2 bg-transparent"></div>

  <div className="absolute left-1/2 mt-2 hidden group-hover:flex transform -translate-x-1/2 gap-2 bg-[#fafafa] p-4 shadow-lg rounded-lg">
    <Link to="/product1">
      <div className="hover:text-red-500 cursor-pointer w-24 h-10 flex items-center justify-center rounded-lg bg-[#fafafa] shadow-md">
        Product 1
      </div>
    </Link>
    <Link to="/product2">
      <div className="hover:text-red-500 cursor-pointer w-24 h-10 flex items-center justify-center rounded-lg bg-[#fafafa] shadow-md">
        Product 2
      </div>
    </Link>
    <Link to="/product3">
      <div className="hover:text-red-500 cursor-pointer w-24 h-10 flex items-center justify-center rounded-lg bg-[#fafafa] shadow-md">
        Product 3
      </div>
    </Link>
    <Link to="/product4">
      <div className="hover:text-red-500 cursor-pointer w-24 h-10 flex items-center justify-center rounded-lg bg-[#fafafa] shadow-md">
        Product 4
      </div>
    </Link>
  </div>
</div>



        
          <Link
          to="/solutions"
          className="hover:text-red-500 cursor-pointer"
        > Solutions</Link>
        <Link
          to="/faq"
          className="hover:text-red-500 cursor-pointer"
        > FAQs</Link>
         <Link
          to="/contact"
          className="hover:text-red-500 cursor-pointer"
        > Contact Us</Link>
      </div>
    </div>
  );
};
