import * as React from 'react';
import solution from '../components/assets/Solution.png';
import { createFileRoute } from '@tanstack/react-router';
import Button from '../components/Button/Button';
import { FaBuilding } from 'react-icons/fa'; 
import { GiPalmTree } from 'react-icons/gi'; 
import { IoMdCafe } from 'react-icons/io'; 
import { MdFactory } from 'react-icons/md'; 

export const Route = createFileRoute('/solutions')({
  component: SolutionComponent,
});

function SolutionComponent() {
  return (
    <>
      <div className="max-w-[1200px] bg-[#F3F1ED] mx-auto rounded-lg flex items-center mb-10">
        <div className="w-1/2">
          <h3 className="relative font-bold text-5xl mb-12 ml-10">Solutions</h3>
          <p className="text-[#3560A1] mb-6 text-2xl font-bold ml-10">
            Uncover how your operations can be transformed by our solutions.
          </p>
          <div className="ml-10">
            <Button label="Download Manual" />
          </div>
        </div>
        <div className="w-1/2 flex justify-end">
          <img
            src={solution}
            alt="Solution Illustration"
            className="rounded-lg object-contain"
          />
        </div>
      </div>

    
      <div className="grid grid-cols-2 gap-6 mx-auto mb-10 max-w-[1000px]">
   
        <div className="bg-[#D9DFDA] h-[310px] rounded-lg flex flex-col p-4">
        <div className="text-3xl font-bold mb-2 ml-4 flex items-center">
        <FaBuilding className="mr-2 text-brown-500" />
            Apartment</div>
          <div className="text-md mb-2 ml-4">
            <strong>Effortless Tank Monitoring Across Vast Apartment Complexes.</strong>
          </div>
          <details className="text-1xl mb-2">
            <summary className="cursor-pointer font-bold text-[#3560A1] ml-4">Solution</summary>
            <p className="mt-2 ml-4">
              Our app provides water usage trends, insights, and automation, simplifying tank monitoring and management.
            </p>
          </details>
          <div className="font-bold text-md mb-2 ml-4">Key Benefits</div>
          <ul className="list-disc pl-6 ml-4">
            <li>Real-time Monitoring</li>
            <li>Automated Management</li>
          </ul>
        </div>

        <div className="bg-[#CBE6EF] h-[310px] rounded-lg flex flex-col p-4">
        <div className="text-3xl font-bold mb-2 ml-4 flex items-center">
        <MdFactory className="mr-2 text-brown-500" />
            Industries</div>
          <div className="text-md mb-2 ml-4">
            <strong>Effortless Water Usage Reporting for Regulatory Compliance and Efficiency.</strong>
          </div>
          <details className="text-md mb-2">
            <summary className="cursor-pointer font-bold text-[#3560A1] ml-4">Solution</summary>
            <p className="mt-2 ml-4">
              Our system generates comprehensive water usage reports on a daily, weekly, and monthly basis.
            </p>
          </details>
          <div className="font-bold text-md mb-2 ml-4">Key Benefits</div>
          <ul className="list-disc pl-6 ml-4">
            <li>Automated Reporting</li>
            <li>Time Savings</li>
          </ul>
        </div>

      
        <div className="bg-[#F3F1ED] h-[310px] rounded-lg flex flex-col p-4">
        <div className="text-3xl font-bold mb-2 ml-4 flex items-center">
          <IoMdCafe className="mr-2 text-brown-500" />
            Restaurant/Cafe</div>
          <div className="text-sm mt-4 ml-4">
            <strong>Streamlining Water Usage for Cost-Efficient and Sustainable Dining Operations</strong>
          </div>
          <details className="text-md mt-2 ml-4">
            <summary className="cursor-pointer font-bold text-[#3560A1]">Solution</summary>
            <p className="mt-2 ml-4">
              Our solution streamlines water usage, offering monitoring and insights tailored for food service establishments.
            </p>
          </details>
          <div className="font-bold text-md mt-2 ml-4">Key Benefits</div>
          <ul className="list-disc pl-6 ml-4">
            <li>Data-Driven Insights</li>
            <li>Sustainability Enhancement</li>
          </ul>
        </div>

        <div className="bg-[#E8E3E7] h-[310px] rounded-lg p-4">
          <div className="text-3xl font-bold mb-2 ml-4 flex items-center">
          <GiPalmTree className="mr-2 text-black-500" />
            Resorts</div>
          <div className="text-lg mt-4 ml-4">
            <strong>Smart Solutions for Streamlined Water Management in Resorts</strong>
          </div>
          <details className="text-md mt-2 ml-4">
            <summary className="cursor-pointer font-bold text-[#3560A1]">Solution</summary>
            <p className="mt-2 ml-4">
              Our system enables real-time monitoring and automated water management for better control and sustainability.
            </p>
          </details>
          <div className="font-bold text-md mt-2 ml-4">Key Benefits</div>
          <ul className="list-disc pl-6 ml-4">
            <li>Water Usage Insights</li>
            <li>Cost Reduction</li>
          </ul>
        </div>
      </div>
    </>
  );
}
